
    import __i18nConfig from '@next-translate-root/i18n'
    import __loadNamespaces from 'next-translate/loadNamespaces'
    import Calendar, { CalendarProps } from 'library/molecules/Calendar';
import Modal, { ActiveModalProps, ModalConfig, RecursiveOmitSetActiveModalId } from 'shared/library/molecules/Modal';
import useTranslation from 'next-translate/useTranslation';
import ModalHeader from 'library/molecules/ModalHeader';
import dayjs from 'dayjs';
const CalendarModal = ({ setActiveModalId, onDateChange }: ModalProps[MODAL_IDS.calendar] & Pick<ActiveModalProps, 'setActiveModalId'>) => {
    const { t } = useTranslation('chargeable');
    return (<>
      <ModalHeader onClose={() => setActiveModalId(null)} headingText={t('select-a-date')}/>
      <Calendar name="calendar" availableDays={{
            after: dayjs().subtract(1, 'day').toDate()
        }} showInput={false} onDateChange={onDateChange}/>
    </>);
};
export enum MODAL_IDS {
    calendar = 'calendar'
}
export type ModalProps = RecursiveOmitSetActiveModalId<{
    [MODAL_IDS.calendar]: CalendarProps;
}>;
export const MODAL_CONFIG: ModalConfig = {
    [MODAL_IDS.calendar]: {
        id: MODAL_IDS.calendar,
        Content: CalendarModal,
        clickOutsideToClose: true
    }
};
/**
 * Please refer to https://www.notion.so/eola/Modals-new-widget-d5a5b2c100c840878761cd28bb3279a2
 * for an explanation on our approach to Modals
 */
const MarketplaceModals = ({ activeModal, setActiveModalId, ...props }: Pick<ActiveModalProps, 'activeModal' | 'setActiveModalId'>) => {
    const ModalContent = activeModal?.Content ? activeModal?.Content : () => null;
    return (<Modal isOpen={!!activeModal} activeModalId={activeModal?.id}>
      <ModalContent setActiveModalId={setActiveModalId} {...props}/>
    </Modal>);
};
export default MarketplaceModals;

    async function __Next_Translate__getStaticProps__194a7319b1e__(ctx) {
      
      return {
        
        
        props: {
          
          ...(await __loadNamespaces({
            ...ctx,
            ...__i18nConfig,
            pathname: 'library//EolaMarketplace/MarketplaceModals',
            loaderName: 'getStaticProps',
            loadLocaleFrom: __i18nConfig && __i18nConfig.loadLocaleFrom || ((l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default)),
          }))
        }
      }
    }
    export { __Next_Translate__getStaticProps__194a7319b1e__ as getStaticProps }
  